<template>
  <div class="language-switcher">

    <span v-if="false" class="material-icons flag-icon">language</span>
    <select class="form-select" v-model="selectedLanguage" @change="changeLanguage">
      <option v-for="language in languages" :key="language.code" :value="language.code">{{ language.name }}</option>
    </select>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selectedLanguage: null,
        languages: [
          { code: 'de', name: 'Deutsch' },
          { code: 'en', name: 'English' },
          { code: 'ar', name: 'العربية'},
          { code: 'tr', name: 'Türkçe'},
          { code: 'uk', name: 'Українська'},
          ],
      };
    },
    created() {
      this.selectedLanguage = this.$store.getters.getLanguage;
    },
    computed: {
    },
    methods: {
      changeLanguage() {
        this.$i18n.locale = this.selectedLanguage;
        this.$store.dispatch('setLanguage', this.selectedLanguage);
        this.$router.push({name:'Home'});
        location.reload();
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .language-switcher {
    display: flex;
    justify-content: center;
    align-items: center;

    select {
      font-size: 16px;
      padding: 8px;
      border: 1px solid $primary;
      border-radius: 4px;
      background-color: #E8F6FC;
      display: block;
      width: 100%;
      padding: .375rem 2.25rem .375rem .75rem;
      -moz-padding-start: calc(0.75rem - 3px);
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right .75rem center;
      background-size: 16px 12px;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &:focus {
        outline: none;
      }
    }
  }

</style>